:root .light {
    color: #495057;
    --fa-primary-color: #495057;
    --fa-secondary-color: #495057;
}

:root .dark {
    color: #e7e7e7;
    --fa-primary-color: #e7e7e7;
}

.is-scrollLocked {
    overflow: hidden;
}

.is-momentumScrollable {
    -webkit-overflow-scrolling: touch;
}
